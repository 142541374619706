<template>
  <div class="login">
    <div style="postion:relative;">
      <van-icon name="cross" class="closeicon" @click="toBack" />
    </div>
    <div>
      <div
        style="height: 3rem;width: 3rem;margin:0.35rem auto;border-radius: 50%;background: #fff;display: flex;justify-content: center;align-items: center;"
      >
        <img src="../assets/img/logo.jpg" class="login_logo" />
      </div>
    </div>
    <div style="display:flex;justify-content: center;margin-bottom:0.4rem;">
      <p
        style="padding: 0.2rem 0.3rem;"
        v-for="(item) in loginList"
        :key="item.id"
        :class="[current==item.id? 'currentindex':'']"
        @click="changeLoginstyle(item.id)"
      >{{item.name}}</p>
    </div>
    <div class="form" v-if="current==0">
      <van-cell-group class="login_form">
        <van-field
          v-model="phone"
          label="账号"
          placeholder="请输入手机号"
          :error-message="phoneErr"
          border
          class="loginfrom_input"
          @blur="phoneValidata"
        />

        <van-field
          v-model="password"
          label="密码"
          placeholder="请输入密码"
          type="password"
          :error-message="passwordErr"
          class="loginfrom_input"
          @blur="passwordValidata"
          border
        />
      </van-cell-group>
      <van-button type="info" class="loginbtn" @click="toLogin">登陆</van-button>
    </div>
    <div class="form" v-if="current==1">
      <van-cell-group class="login_form">
        <van-field
          v-model="yzphone"
          label="账号"
          placeholder="请输入手机号"
          :error-message="yzphoneErr"
          border
          class="loginfrom_input"
          @blur="phoneValidatas"
        />

        <van-field
          v-model="verification"
          center
          clearable
          class="loginfrom_input"
          label="验证码"
          placeholder="短信验证码"
          use-button-slot
        >
          <van-button
            slot="button"
            size="small"
            type="primary"
            @click="sendCode"
            v-if="yzstatus"
          >发送验证码</van-button>
          <van-button slot="button" size="small" type="primary" v-else>{{timer}}S</van-button>
        </van-field>
      </van-cell-group>
      <van-button type="info" class="loginbtn" @click="toLogin">登陆</van-button>
    </div>
    <div class="login-modify">
      <p @click="toRegister">新用户注册</p>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
export default {
  data() {
    return {
      show: false,
      value: "",
      yzstatus: true,
      is: false,
      password: "",
      pais: true,
      key: "",
      phone: "",
      current: 0,
      verification: "",
      timer: 60,
      yzphone: "",
      loginList: [
        {
          id: 0,
          name: "账号登录"
        },
        {
          id: 1,
          name: "验证码登录"
        }
      ],
      phoneErr: "",
      yzphoneErr: "",
      passwordErr: ""
    };
  },

  computed: {},
  methods: {
    //获取验证码
    sendCode() {
      this.$http
        .getCode({ phone: this.yzphone })
        .then(res => {
          console.log(res);
          this.key = res.key;
          this.timer = 60;
          var settimer = setInterval(() => {
            this.timer = this.timer - 1;
            if (this.timer > 0) {
              this.yzstatus = false;
            } else {
              this.yzstatus = true;
              clearInterval("settimer");
            }
          }, 1000);
        })
        .catch(err => {
          Toast("获取验证码失败");
        });
    },
    toLogin() {
      if (this.current == 0) {
        let obj = {
          phone: this.phone,
          password: this.password,
          type: "account"
        };
        this.$http
          .getToLogin(obj)
          .then(res => {
            let token = res.access_token;
            let username = this.phone;
            window.sessionStorage.setItem("Token", token); //存登陆状态
            window.sessionStorage.setItem("username", username);
            Toast.success("登陆成功");
            this.$router.back();
          })
          .catch(err => {
            Toast("账号密码错误");
          });
        return;
      } else {
        let obj = {
          phone: this.yzphone,
          verification_key: this.key,
          verification_code: this.verification,
          type: "phone"
        };
        this.$http
          .getToLogin(obj)
          .then(res => {
            console.log(res, 311231);
            let token = res.access_token;
            let username = this.yzphone;
            window.sessionStorage.setItem("Token", token); //存登陆状态
            window.sessionStorage.setItem("username", username);
            Toast.success("登陆成功");
            this.$router.back();
          })
          .catch(err => {
            Toast("登录失败");
          });
      }
    },
    phoneValidata() {
      let phone = this.phone;
      if (!/^1[3456789]\d{9}$/.test(phone)) {
        this.phoneErr = "请输入正确手机号";
        return false;
      } else {
        this.phoneErr = "";
      }
    },
    phoneValidatas() {
      let phone = this.yzphone;
      if (!/^1[3456789]\d{9}$/.test(phone)) {
        this.yzphoneErr = "请输入正确手机号";
        return false;
      } else {
        this.yzphoneErr = "";
      }
    },
    passwordValidata() {
      let password = this.password;
      if (password.length < 6) {
        this.passwordErr = "密码长度不小于6位";
        return false;
      } else {
        this.passwordErr = "";
      }
    },
    toBack() {
      this.$router.back();
    },
    toRegister() {
      this.$router.push("/register");
    },
    changeLoginstyle(id) {
      this.current = id;
    }
  },
  watch: {}
};
</script>

<style scoped>
.login_logo {
  width: 2.1rem;
  height: 2rem;
  display: block;
}
.login_title {
  text-align: center;
  margin: 0.3rem 0;
  font-weight: bold;
  font-size: 0.5rem;
}
.login-form {
  margin-bottom: 0.3rem;
}
.form {
  padding: 0 0.5rem;
}
.van-cell-group {
  background: #fff;
}
.loginfrom_input {
  margin-bottom: 0.27rem;
  border: 1px solid #ddd;
}
.currentindex {
  color: #1989fa;
}
.loginbtn {
  display: block;
  width: 70%;
  margin: 0 auto;
  height: 0.7rem;
  text-align: center;
  line-height: 0.7rem;
  font-size: 0.3rem;
  font-weight: bold;
}
.login-modify {
  margin-top: 0.3rem;
}
.login-modify p {
  float: right;
  margin-right: 0.5rem;
  font-size: 0.3rem;
}
.closeicon {
  right: 0.6rem;
  top: 0.1rem;
  position: absolute;
  padding: 0.3rem;
}
</style>
